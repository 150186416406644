<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <b-row>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.farmer_name')}}</th>
                                                                <th>{{$t('bsri_demandDIS.farmer_nid_number')}}</th>
                                                                <th>{{ $t('bsri_demandDIS.mobile_number') }}</th>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ demandCollection.name }}</td>
                                                                <td>{{ $n(demandCollection.nid_no, { useGrouping: false }) }}</td>
                                                                <td colspan="2">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(demandCollection.mobile_no, { useGrouping: false }) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3 p-1">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.checklist_info') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.checklist_name')}}</th>
                                                                <th>{{$t('bsri_demandDIS.checklist_des')}}</th>
                                                                <th>{{$t('globalTrans.status')}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(checklist, index) in demandCollection.checklists" :key="index">
                                                                <td>
                                                                    {{ checklist.text }}
                                                                </td>
                                                                <td>
                                                                    {{ checklist.desc }}
                                                                </td>
                                                                <td>
                                                                    <ValidationProvider name="Please slect yes or no" :vid="`yes_no${index}`" rules="required">
                                                                    <b-form-group
                                                                        class="row"
                                                                        label-cols-sm="1"
                                                                        :label-for="`yes_no${index}`"
                                                                        slot-scope="{ valid, errors }"
                                                                        >
                                                                        <b-form-radio-group
                                                                            v-model="checklist.is_checked"
                                                                            :options="yesNo"
                                                                            class="mb-3"
                                                                            value-field="item"
                                                                            text-field="name"
                                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                                        ></b-form-radio-group>
                                                                        <div class="invalid-feedback d-block">
                                                                        {{ errors[0] }}
                                                                        </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Comments (En)" vid="comments" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_en')}}
                                                        </template>
                                                            <b-form-textarea
                                                            id="description"
                                                            v-model="demandCollection.comments_en"
                                                            rows="1"
                                                            max-rows="2"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <ValidationProvider name="Comments (Bn)" vid="comments_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="2"
                                                            label-for="comments_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.comments_bn')}}
                                                        </template>
                                                            <b-form-textarea
                                                            id="description"
                                                            v-model="demandCollection.comments_bn"
                                                            rows="1"
                                                            max-rows="2"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <p>
                                                        <label for='terms'>
                                                        <input id='terms' type='checkbox' v-model='terms' /> <span class="text-danger"> {{ $t('bsri_demandDIS.accept_terms') }}</span>
                                                        </label>
                                                    </p>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <div class="col text-right">
                                                        <b-button type="submit" variant="primary" class="mr-2" :disabled='isDisabled'>{{ saveBtnName }}</b-button>
                                                        &nbsp;
                                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { checklistVerificaitonUpdate } from '../../api/routes'

export default {
    props: ['id', 'fiscalYearId'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            onlyCreate: true,
            demandCollection: {
                checklists: [],
                comments_en: '',
                comments_bn: ''
            },
            yesNo: [
                { item: 1, name: this.$t('bsri_demandDIS.yes') },
                { item: 0, name: this.$t('bsri_demandDIS.no') }
            ],
            terms: false
        }
    },
    created () {
        if (this.id) {
            this.getEditingData()
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        },
        isDisabled: function () {
            return !this.terms
        },
        checklists: function () {
            const checklist = this.$store.state.incentiveGrant.commonObj.checkList.filter(item => item.fiscal_year_id === this.demandCollection.fiscal_year_id)
            return checklist.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { checklist: item.checklist_id, value: item.value, text: item.text_bn, desc: item.description_bn }
                } else {
                    return { checklist: item.checklist_id, value: item.value, text: item.text_en, desc: item.description_en }
                }
            })
        }
    },
    methods: {
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            const data = JSON.parse(JSON.stringify(Object.assign({}, tmpData)))
            this.demandCollection.demand_collection_id = data.id
            this.demandCollection.fiscal_year_id = data.fiscal_year_id
            this.demandCollection.nid_no = data.nid_no
            this.demandCollection.name = data.farmer_name
            this.demandCollection.far_general_info_id = data.far_general_info_id
            this.demandCollection.name = this.locale === 'bn' ? data.name_bn : data.name
            this.demandCollection.mobile_no = data.mobile_no
            this.demandCollection.checklists = this.checklists
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, checklistVerificaitonUpdate, this.demandCollection)
            }
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                // this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        getRelatinalData (data) {
            return data.map(item => {
                const checklistName = { check_list_name: this.locale === 'bn' ? item.text_bn : item.text_en }
                const checklistDes = { check_list_des: this.locale === 'bn' ? item.description_bn : item.description_en }
                return Object.assign({}, data, checklistName, checklistDes)
            })
        }
    }
}
</script>
