<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <b-overlay :show="isLoading">
                                    <b-table-simple striped bordered small>
                                        <b-tr>
                                            <b-th>{{ $t('bsri_demandDIS.fiscal_year') }}</b-th>
                                            <b-td>{{ itemData.fiscal_year }}</b-td>
                                            <b-th>{{ $t('bsri_demandDIS.method_of_cultivation') }}</b-th>
                                            <b-td>{{ itemData.cultivationname }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>{{ $t('bsri_demandDIS.farmer_name') }}</b-th>
                                            <b-td>{{ itemData.name }}</b-td>
                                            <b-th>{{ $t('bsri_demandDIS.farmer_father_name') }}</b-th>
                                            <b-td>{{ itemData.father_name }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>{{ $t('bsri_demandDIS.farmer_mother_name') }}</b-th>
                                            <b-td>{{ itemData.mother_name }}</b-td>
                                            <b-th>{{ $t('bsri_demandDIS.farmer_dob') }}</b-th>
                                            <b-td>{{ itemData.date_of_birth|dateFormat }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>{{ $t('bsri_demandDIS.farmer_gender') }}</b-th>
                                            <b-td>{{ itemData.gender_name }}</b-td>
                                            <b-th>{{ $t('bsri_demandDIS.farmer_blood_group') }}</b-th>
                                            <b-td>{{ itemData.blood_group_name }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>{{ $t('bsri_demandDIS.mobile_number') }}</b-th>
                                            <b-td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(itemData.mobile_no, { useGrouping: false }) }}</b-td>
                                            <b-th>{{ $t('bsri_demandDIS.farmer_nid_number') }}</b-th>
                                            <b-td>{{ $n(itemData.nid_no, { useGrouping: false }) }}</b-td>
                                        </b-tr>
                                    </b-table-simple>
                                    <template>
                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3 p-1">
                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.farmers_present_address') }}</h5>
                                        </div>
                                    </template>
                                    <b-table-simple striped bordered small>
                                        <b-tr>
                                            <b-th>{{$t('bsri_demandDIS.farmer_district')}}</b-th>
                                            <b-td>{{ itemData.district_name_pre }}</b-td>
                                            <b-th>{{$t('bsri_demandDIS.farmer_upazilla')}}</b-th>
                                            <b-td>{{ itemData.upazilla_name_pre }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>{{$t('bsri_demandDIS.farmer_union')}}</b-th>
                                            <b-td>{{ itemData.union_name_pre}}</b-td>
                                            <b-th>{{$t('bsri_demandDIS.farmer_ward')}}</b-th>
                                            <b-td>{{ itemData.word_name_pre }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>{{$t('bsri_demandDIS.farmer_village')}}</b-th>
                                            <b-td>{{ itemData.village_name_pre}}</b-td>
                                            <b-th>{{$t('bsri_demandDIS.farmer_block')}}</b-th>
                                            <b-td>{{ itemData.block_name_pre }}</b-td>
                                        </b-tr>
                                    </b-table-simple>
                                    <template>
                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3 p-1">
                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.farmers_permanent_address') }}</h5>
                                        </div>
                                    </template>
                                    <b-table-simple striped bordered small>
                                        <b-tr>
                                            <b-th>{{$t('bsri_demandDIS.farmer_district')}}</b-th>
                                            <b-td>{{ itemData.district_name_per }}</b-td>
                                            <b-th>{{$t('bsri_demandDIS.farmer_upazilla')}}</b-th>
                                            <b-td>{{ itemData.upazilla_name_per }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>{{$t('bsri_demandDIS.farmer_union')}}</b-th>
                                            <b-td>{{ itemData.union_name_per}}</b-td>
                                            <b-th>{{$t('bsri_demandDIS.farmer_ward')}}</b-th>
                                            <b-td>{{ itemData.word_name_per }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-th>{{$t('bsri_demandDIS.farmer_village')}}</b-th>
                                            <b-td>{{ itemData.village_name_per}}</b-td>
                                            <b-th>{{$t('bsri_demandDIS.farmer_block')}}</b-th>
                                            <b-td>{{ itemData.block_name_per }}</b-td>
                                        </b-tr>
                                    </b-table-simple>
                                    <template>
                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3 p-1">
                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.land_information') }}</h5>
                                        </div>
                                    </template>
                                    <b-table-simple striped bordered small>
                                        <b-tr>
                                            <b-th>{{$t('bsri_demandDIS.total_land_quantity')}}</b-th>
                                            <b-td>{{ $n(itemData.total_land, { useGrouping: false }) }}</b-td>
                                            <b-th>{{ $t('bsri_demandDIS.crop_name') }}</b-th>
                                            <b-td>{{ itemData.cropname }}</b-td>
                                        </b-tr>
                                    </b-table-simple>
                                    <template>
                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3 p-1">
                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.checklist_info') }}</h5>
                                        </div>
                                    </template>
                                    <b-table-simple striped bordered small v-if="checkList.length">
                                        <b-tr>
                                            <b-th>{{ $t('bsri_demandDIS.sl') }}</b-th>
                                            <b-th>{{ $t('bsri_demandDIS.checklist_name') }}</b-th>
                                            <b-th>{{ $t('bsri_demandDIS.checklist_des') }}</b-th>
                                            <b-th>{{ $t('globalTrans.status') }}</b-th>
                                        </b-tr>
                                        <b-tr v-for="(clist, index) in checkList" :key="index">
                                            <b-td>{{ $n(index + 1) }}</b-td>
                                            <b-td>{{ clist.text }}</b-td>
                                            <b-td>{{ clist.desc }}</b-td>
                                            <b-td>
                                                <span v-if="clist.checked === 1" class="badge badge-success"> {{ $t('bsri_demandDIS.yes') }} </span>
                                                <span v-else class="badge badge-danger"> {{ $t('bsri_demandDIS.no') }} </span>
                                            </b-td>
                                        </b-tr>
                                    </b-table-simple>
                                    <p class="text-center text-danger" v-else> {{ $t('bsri_demandDIS.check_list_not_done') }} </p>
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { itemDataShow } from '../../api/routes'

export default {
    props: ['id'],
    components: {
    },
    data () {
        return {
            itemData: '',
            checkList: [],
            isLoading: false
        }
    },
    created () {
        this.getItem(this.$props.id)
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        },
        bloodGroupList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'O-' : 'ও−' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'O+' : 'ও+' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'A-' : 'এ−' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'A+' : 'এ+' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'B-' : 'বি−' },
                { value: 6, text: this.$i18n.locale === 'en' ? 'B+' : 'বি+' },
                { value: 7, text: this.$i18n.locale === 'en' ? 'AB-' : 'এবি−' },
                { value: 8, text: this.$i18n.locale === 'en' ? 'AB+' : 'এবি+' }
            ]
           return list
        }
    },
    methods: {
        async getItem (itemDataId) {
            this.isLoading = true
            RestApi.getData(incentiveGrantServiceBaseUrl, itemDataShow + '/' + itemDataId).then(response => {
                if (response.success) {
                    this.itemData = this.getRelatinalData(response.data)
                    this.checkList = this.getChecklistRelationalData(response.checklistdata)
                    this.isLoading = false
                }
            })
        },
        getRelatinalData (data) {
            const districtObj = this.$store.state.commonObj.districtList.find(district => district.value === data.district_id)
            const districtData = { district_name_pre: districtObj !== undefined ? (this.locale === 'bn' ? districtObj.text_bn : districtObj.text_en) : '' }
            const districtObjPer = this.$store.state.commonObj.districtList.find(district => district.value === data.per_district_id)
            const districtDataPer = { district_name_per: districtObjPer !== undefined ? (this.locale === 'bn' ? districtObjPer.text_bn : districtObjPer.text_en) : '' }
            const upazillaObj = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === data.upazilla_id)
            const upazillaData = { upazilla_name_pre: upazillaObj !== undefined ? (this.locale === 'bn' ? upazillaObj.text_bn : upazillaObj.text_en) : '' }
            const upazillaObjPer = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === data.per_upazilla_id)
            const upazillaDataPer = { upazilla_name_per: upazillaObjPer !== undefined ? (this.locale === 'bn' ? upazillaObjPer.text_bn : upazillaObjPer.text_en) : '' }
            const unionObj = this.$store.state.commonObj.unionList.find(union => union.value === data.union_id)
            const unionData = { union_name_pre: unionObj !== undefined ? (this.locale === 'bn' ? unionObj.text_bn : unionObj.text_en) : '' }
            const unionObjPer = this.$store.state.commonObj.unionList.find(union => union.value === data.union_id)
            const unionDataPer = { union_name_per: unionObjPer !== undefined ? (this.locale === 'bn' ? unionObjPer.text_bn : unionObjPer.text_en) : '' }
            const wardObj = this.$store.state.commonObj.wardList.find(ward => ward.value === data.ward_id)
            const wardData = { word_name_pre: wardObj !== undefined ? (this.locale === 'bn' ? wardObj.text_bn : wardObj.text_en) : '' }
            const wardObjPer = this.$store.state.commonObj.wardList.find(ward => ward.value === data.per_ward_id)
            const wardDataPer = { word_name_per: wardObjPer !== undefined ? (this.locale === 'bn' ? wardObjPer.text_bn : wardObjPer.text_en) : '' }

            const villageData = { village_name_pre: (this.locale === 'bn' ? data.village_bn : data.village_en) }
            const villageDataPer = { village_name_per: (this.locale === 'bn' ? data.per_village_bn : data.per_village_en) }
            const blockData = { block_name_pre: (this.locale === 'bn' ? data.block_bn : data.block_en) }
            const blockDataPer = { block_name_per: (this.locale === 'bn' ? data.per_block_bn : data.per_block_en) }

            const genderObj = this.$store.state.ExternalUserIrrigation.genderList.find(block => block.value === data.gender_id)
            const genderData = { gender_name: genderObj !== undefined ? (this.locale === 'bn' ? genderObj.text_bn : genderObj.text_en) : '' }
            const bloodGroup = this.bloodGroupList.find(bloodSingleGroup => bloodSingleGroup.value === data.blood_group_id)
            const bloodGroupData = { blood_group_name: bloodGroup !== undefined ? bloodGroup.text : '' }
            const ficalYear = this.$store.state.commonObj.fiscalYearList.find(item => item.value === data.fiscal_year_id)
            const ficalYearData = { fiscal_year: ficalYear !== undefined ? (this.locale === 'bn' ? ficalYear.text_bn : ficalYear.text_en) : '' }
            const cropList = this.$store.state.incentiveGrant.commonObj.cropList.find(item => item.value === data.crop_name_id)
            const cropListData = { cropname: cropList !== undefined ? (this.locale === 'bn' ? cropList.text_bn : cropList.text_en) : '' }
            const cultivation = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(item => item.value === data.cultivation_id)
            const cultivationData = { cultivationname: cultivation !== undefined ? (this.locale === 'bn' ? cultivation.text_bn : cultivation.text_en) : '' }
            const farmerName = { name: this.locale === 'bn' ? data.name_bn : data.name }
            const fatherName = { father_name: this.locale === 'bn' ? data.father_name_bn : data.father_name }
            const motherName = { mother_name: this.locale === 'bn' ? data.mother_name_bn : data.mother_name }
            return Object.assign({}, data, districtData, districtDataPer, upazillaData, upazillaDataPer, unionData, unionDataPer, wardData, wardDataPer, villageData, villageDataPer, blockData, blockDataPer, genderData, bloodGroupData, farmerName, fatherName, motherName, ficalYearData, cropListData, cultivationData)
        },
        getChecklistRelationalData (data) {
            return data.map(item => {
            if (this.$i18n.locale === 'bn') {
                    return { text: item.name_bn, desc: item.description_bn, checked: item.is_checked }
                } else {
                    return { text: item.name, desc: item.description, checked: item.is_checked }
                }
            })
        }
    }
}
</script>
